import React from "react"
import Button from "../button"

const HomeWhySlide = props => {
    const { headline, homePageBlurb, image } = props.slide
    const index = props.index
    const current = props.current
    let classNames = "why-slide"

    if (current === index) classNames += " why-slide--current"
    else if (current - 1 === index) classNames += " why-slide--previous"
    else if (current + 1 === index) classNames += " why-slide--next"

    return (
        <div className={classNames} data-index={index}>
            <div className="why-slide__wrapper">
                <figure className="why-slide__image">
                    <img
                        src={image.sourceUrl}
                        srcSet={image.srcSet}
                        alt={headline}
                    />
                </figure>
                <div className="why-slide__card">
                    <div className="why-slide__card-wrapper">
                        <h2>{headline}</h2>
                        <p>{homePageBlurb}</p>
                        <Button
                            modifier={`primary`}
                            link={`/why-urban-design-matters`}
                            text={`learn more`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeWhySlide
