import React from "react"
import ChevronLeft from "../assets/svg/chevron-left.svg"
import ChevronRight from "../assets/svg/chevron-right.svg"

const NumberedTabs = ({ current, length, next, prev }) => {
    return (
        <div className="numbered-tabs">
            <div className="numbered-tabs__wrapper">
                <ChevronLeft onClick={prev} />
                <div>
                    {current}/{length}
                </div>
                <ChevronRight onClick={next} />
            </div>
        </div>
    )
}

export default NumberedTabs
