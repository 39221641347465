import React from "react"
import SEO from "../components/seo"
import HomeHero from "../components/home-hero/homeHero"
import HomeAbout from "../components/home-about/index"
import HomeWhy from "../components/home-why/homeWhy"
import HomeProjects from "../components/home-projects/homeProjects"
import HomeNews from "../components/home-news/homeNews"
import GetInvolved from "../components/get-involved"

const IndexPage = () => (
    <>
        <SEO title="Home" />
        <HomeHero />
        <HomeAbout />
        <HomeWhy />
        <HomeProjects />
        <HomeNews />
        <GetInvolved isHome={`true`} />
    </>
)

export default IndexPage
