import React from "react"

const HeroCopy = ({ content }) => {
    return (
        <div className="hero-content">
            <div
                className="hero-content__wrapper"
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    )
}

export default HeroCopy
