import React from "react"
import Button from "../button"

const HomeAboutContent = props => {
    return (
        <div className="home-about__content">
            <div dangerouslySetInnerHTML={{ __html: props.copy }}></div>
            <Button
                modifier={`primary`}
                text={props.cta.ctaText}
                link={props.cta.ctaLink}
            />
        </div>
    )
}

export default HomeAboutContent
