import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ScrollableSection from "../scrollable-section"
import HomeAboutContent from "./homeAboutContent"
import HomeAboutImage from "./homeAboutImage"

const HOME_ABOUT_QUERY = graphql`
    query HomeAboutQuery {
        wpgraphql {
            pageBy(uri: "home") {
                homeAbout {
                    content {
                        backgroundWord
                        copy
                        cta {
                            ctaLink
                            ctaText
                        }
                    }
                    imageFields {
                        projectArea
                        projectName
                        projectImage {
                            srcSet
                            sourceUrl
                        }
                    }
                }
            }
        }
    }
`

const HomeAbout = () => {
    const {
        wpgraphql: {
            pageBy: {
                homeAbout: { content, imageFields },
            },
        },
    } = useStaticQuery(HOME_ABOUT_QUERY)

    return (
        <ScrollableSection classNames="home-about">
            <div className="home-about__wrapper">
                <HomeAboutContent {...content} />
                <HomeAboutImage {...imageFields} />
            </div>
            <p className="vertical-text">{content.backgroundWord}</p>
        </ScrollableSection>
    )
}

export default HomeAbout
