import React from "react"

const HeroCard = ({ headline, subHeadline }) => {
    return (
        <div className="hero-card">
            <div className="hero-card__wrapper">
                <p>{subHeadline}</p>
                <h1>{headline}</h1>
            </div>
        </div>
    )
}

export default HeroCard
