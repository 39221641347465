import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ScrollableSection from "../scrollable-section"
import HeroCard from "./heroCard"
import HeroVideo from "./heroVideo"
import HeroCopy from "./heroCopy"
import Img from "gatsby-image"

const HOME_HERO_QUERY = graphql`
    query HomeHeroQuery {
        wpgraphql {
            pageBy(uri: "home") {
                homeHero {
                    homeHero {
                        copy
                        fieldGroupName
                        headline
                        subHeadline
                        video
                    }
                }
            }
        }
        placeholderImage: file(relativePath: { eq: "OBD_line-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const HomeHero = ({ home }) => {
    const {
        wpgraphql: {
            pageBy: {
                homeHero: { homeHero },
            },
        },
        placeholderImage,
    } = useStaticQuery(HOME_HERO_QUERY)

    return (
        <ScrollableSection classNames="hero hero--home">
            <Img
                fluid={placeholderImage.childImageSharp.fluid}
                style={{
                    position: `absolute`,
                    top: 0,
                    left: 0,
                    width: `100%`,
                    height: `100%`,
                    opacity: `0.045`,
                    zIndex: -1,
                }}
            />
            <div className="hero__wrapper">
                <HeroVideo id={homeHero.video} />
                <HeroCard
                    headline={homeHero.headline}
                    subHeadline={homeHero.subHeadline}
                />
            </div>
            <HeroCopy content={homeHero.copy} />
        </ScrollableSection>
    )
}

export default HomeHero
