import React from "react"

const HomeWhyNumber = props => {
    const index = props.index
    const current = props.current
    let classNames = "home-why__number"

    if (current === index) classNames += " home-why__number--current"

    return (
        <div className={classNames}>
            <p>0{index + 1}</p>
            <span className="home-why__number-line"></span>
        </div>
    )
}

export default HomeWhyNumber
