import React from "react"

const HomeAboutImage = props => {
    return (
        <div className="home-about__image">
            <img
                src={props.projectImage.sourceUrl}
                srcSet={props.projectImage.sourceSet}
                alt={props.projectName}
            />
            {props.projectName && (
                <p className="image-title">- {props.projectName}</p>
            )}
            {props.projectArea && (
                <p className="small-text">{props.projectArea}</p>
            )}
        </div>
    )
}

export default HomeAboutImage
