import React from "react"

const HeroVideo = ({ id }) => {
    return (
        <div className="hero-video">
            <div className="vimeo-wrapper">
                <iframe
                    title="vimeo"
                    src={`https://player.vimeo.com/video/${id}?background=1&autoplay=1&loop=1&byline=0&title=0`}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    )
}

export default HeroVideo
