import { StaticQuery, graphql } from "gatsby"
import React, { Component } from "react"
import { TweenMax, TimelineMax, Expo } from "gsap/TweenMax"
import ScrollableSection from "../scrollable-section"
import HomeWhySlide from "./homeWhySlide"
import Img from "gatsby-image"
import NumberedTabs from "../numbered-tabs"
import HomeWhyNumber from "./homeWhyNumber"

const HOME_WHY_QUERY = graphql`
    query HomeWhyQuery {
        wpgraphql {
            whyUrbanDesignMatters {
                nodes {
                    whyUrbanDesignMatters {
                        headline
                        homePageBlurb
                        image {
                            sourceUrl
                            srcSet
                        }
                    }
                }
            }
        }

        placeholderImage: file(relativePath: { eq: "OBD_line-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

class HomeWhy extends Component {
    constructor(props) {
        super(props)
        this.slides = []
        this.state = {
            current: 0,
            previous: null,
        }
    }

    handlePreviousClick = () => {
        const next = this.state.current - 1
        const previous = this.state.current

        this.setState({
            current:
                next < 0
                    ? this.props.data.wpgraphql.whyUrbanDesignMatters.nodes
                          .length - 1
                    : next,
        })

        setTimeout(() => {
            this.slideAnimationTL(this.state.current, previous)
        }, 200)
    }

    handleNextClick = () => {
        const next = this.state.current + 1
        const previous = this.state.current

        this.setState({
            current:
                next ===
                this.props.data.wpgraphql.whyUrbanDesignMatters.nodes.length
                    ? 0
                    : next,
        })

        setTimeout(() => {
            this.slideAnimationTL(this.state.current, previous)
        }, 200)
    }

    imageAnimationTL = (slideInImage, slideOutImage) => {
        const tl = new TimelineMax()
        tl.to(
            slideOutImage,
            1.1,
            { xPercent: 100, ease: Expo.easeInOut },
            0.1
        ).fromTo(
            slideInImage,
            1.1,
            { xPercent: -115, scale: 1.3 },
            { xPercent: 0, scale: 1, ease: Expo.easeInOut },
            0.1
        )
        return tl
    }

    contentAnimationTL = (
        slideInContentWrapper,
        slideOutContentWrapper,
        slideInContent,
        slideOutContent
    ) => {
        const tl = new TimelineMax()

        tl.set(slideInContentWrapper, { autoAlpha: 0, y: 0 })
            .set(slideOutContentWrapper, { y: 0 })
            .staggerTo(
                slideOutContent,
                0.5,
                { y: -25, autoAlpha: 0, ease: Expo.easeInOut },
                0.1
            )
            .set(slideInContentWrapper, { autoAlpha: 1 })
            .set(slideOutContentWrapper, { autoAlpha: 0 })
            .staggerFromTo(
                slideInContent,
                0.5,
                { y: 30, autoAlpha: 0 },
                { autoAlpha: 1, y: 0, ease: Expo.easeOut },
                0.1
            )

        return tl
    }

    slideAnimationTL = (current, previous) => {
        const masterTL = new TimelineMax()
        //Slide Out Items
        const slideOut = this.slides[previous]
        const slideOutImage = slideOut.querySelector(".why-slide__image img")
        const slideOutContentWrapper = slideOut.querySelector(
            ".why-slide__card-wrapper"
        )
        const slideOutContent = slideOut.querySelectorAll(
            ".why-slide__card-wrapper > *"
        )

        //Slide In Items
        const slideIn = this.slides[current]
        const slideInImage = slideIn.querySelector(".why-slide img")
        const slideInContentWrapper = slideIn.querySelector(
            ".why-slide__card-wrapper"
        )
        const slideInContent = slideIn.querySelectorAll(
            ".why-slide__card-wrapper > *"
        )

        masterTL
            .set(slideIn, { autoAlpha: 1 })
            .add(this.imageAnimationTL(slideInImage, slideOutImage), 0)
            .add(
                this.contentAnimationTL(
                    slideInContentWrapper,
                    slideOutContentWrapper,
                    slideInContent,
                    slideOutContent
                ),
                0
            )

        return masterTL
    }

    // use React lifecycle methods as needed
    componentDidMount() {
        this.slides = [...document.querySelectorAll(".why-slide")]

        this.slides.forEach(slide => {
            if (!slide.classList.contains("why-slide--current"))
                TweenMax.set(slide, { autoAlpha: 0 })
        })
    }

    render() {
        const {
            nodes: slides,
        } = this.props.data.wpgraphql.whyUrbanDesignMatters // add more props here as needed
        const { placeholderImage } = this.props.data
        const { current } = this.state
        const underlineTransform = {
            transform: `translateX(${current * (300 / slides.length)}%)`,
        }
        return (
            <ScrollableSection classNames={`home-why`}>
                <div className="home-why__wrapper home-why__wrapper--bg">
                    <Img
                        fluid={placeholderImage.childImageSharp.fluid}
                        style={{
                            position: `absolute`,
                            top: 0,
                            left: 0,
                            width: `100%`,
                            height: `100%`,
                            opacity: `0.04`,
                        }}
                    />
                </div>
                <div className="home-why__wrapper home-why__wrapper--elements">
                    <span className="home-why__line"></span>
                    <span className="home-why__line"></span>
                    <div className="home-why__numbers-wrapper">
                        {slides.map((slide, index) => (
                            <HomeWhyNumber
                                key={index * 73}
                                index={index}
                                current={current}
                            />
                        ))}
                        <div
                            className="home-why__sliding-line"
                            style={underlineTransform}
                        ></div>
                    </div>
                </div>
                <div className="home-why__wrapper home-why__wrapper--slides">
                    {slides.map(({ whyUrbanDesignMatters: slide }, index) => (
                        <HomeWhySlide
                            key={slide.headline}
                            slide={slide}
                            index={index}
                            current={current}
                        />
                    ))}
                    <NumberedTabs
                        current={current + 1}
                        length={slides.length}
                        next={this.handleNextClick}
                        prev={this.handlePreviousClick}
                    />
                </div>
            </ScrollableSection>
        )
    }
}

export default props => (
    <StaticQuery
        query={HOME_WHY_QUERY}
        render={data => <HomeWhy data={data} {...props} />}
    />
)
