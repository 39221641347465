import { StaticQuery, graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import React, { Component } from "react"
import { TweenMax, TimelineMax, Power3, Expo } from "gsap/TweenMax"
import ScrollableSection from "../scrollable-section"
import NumberedTabs from "../numbered-tabs"
import ViewAll from "../view-all"
import Button from "../button"
import ViewProject from "../../assets/svg/view-project.svg"

const HOME_PROJECTS_QUERY = graphql`
    query HomeProjectQuery {
        wpgraphql {
            projects(where: { categoryName: "featured-project" }) {
                nodes {
                    featuredImage {
                        node {
                            sourceUrl
                            srcSet
                        }
                    }
                    title
                    id
                    slug
                    excerpt
                }
            }
        }
        placeholderImage: file(relativePath: { eq: "obd-bg.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

class HomeProjects extends Component {
    constructor(props) {
        super(props)
        this.slides = []
        this.mainCurtain = React.createRef()
        this.projectTitle = React.createRef()
        this.projectBlurb = React.createRef()
        this.projectLink = React.createRef()
        this.mainImage = React.createRef()
        this.blurImage = React.createRef()
        this.state = {
            current: 0,
            previous: 2,
        }
    }

    handlePreviousClick = () => {
        const next = this.state.current - 1
        this.slideAnimationTL(next, "previous")
    }

    handleNextClick = () => {
        const next = this.state.current + 1
        this.slideAnimationTL(next)
    }

    firstHalf = (next, direction) => {
        const tl = new TimelineMax({
            onComplete: () => {
                if (direction === "next") {
                    this.setState({
                        current:
                            next ===
                            this.props.data.wpgraphql.projects.nodes.length
                                ? 0
                                : next,
                        previous:
                            next ===
                            this.props.data.wpgraphql.projects.nodes.length
                                ? 2
                                : next - 1,
                    })
                } else {
                    this.setState({
                        current:
                            next < 0
                                ? this.props.data.wpgraphql.projects.nodes
                                      .length - 1
                                : next,
                        previous: next < 0 ? 0 : next + 1,
                    })
                }
            },
        })

        tl.set(this.mainCurtain.current, {
            transformOrigin: direction === "next" ? "left" : "right",
        })
            .to(
                this.mainCurtain.current,
                0.6,
                {
                    transform: "scaleX(1)",
                    ease: Expo.easeInOut,
                },
                0
            )
            .staggerTo(
                [this.projectTitle.current, this.projectBlurb.current],
                0.9,
                { y: 25, autoAlpha: 0, ease: Power3.easeOut },
                -0.1,
                0
            )
            .to(
                this.blurImage.current,
                0.9,
                {
                    scale: 1.3,
                    autoAlpha: 0,
                    ease: Power3.easeOut,
                },
                0
            )

        return tl
    }

    secondHalf = direction => {
        const tl = new TimelineMax()

        tl.set(this.mainCurtain.current, {
            transformOrigin: direction === "next" ? "right" : "left",
        })
            .fromTo(
                this.mainImage.current,
                0.9,
                { scale: 1.3 },
                { scale: 1, ease: Expo.easeOut },
                0
            )
            .to(
                this.mainCurtain.current,
                0.9,
                { scaleX: 0, ease: Expo.easeOut },
                0
            )
            .staggerFromTo(
                [this.projectTitle.current, this.projectBlurb.current],
                0.9,
                { y: -30, autoAlpha: 0 },
                { y: 0, autoAlpha: 1, ease: Expo.easeOut },
                -0.1,
                0
            )
            .to(
                this.blurImage.current,
                0.9,
                { autoAlpha: 1, scale: 1, ease: Expo.easeOut },
                0
            )

        return tl
    }

    slideAnimationTL = (next, direction = "next") => {
        const masterTL = new TimelineMax()
        masterTL
            .add(this.firstHalf(next, direction), 0)
            .call(this.secondHalf, [direction], 1)
        return masterTL
    }

    // use React lifecycle methods as needed
    componentDidMount() {}

    render() {
        const { nodes: slides } = this.props.data.wpgraphql.projects
        const { current, previous } = this.state
        if (slides.length > 0) {
            return (
                <ScrollableSection classNames={`home-projects`}>
                    <div className="home-projects__wrapper">
                        <div className="project-slide">
                            <div className="project-slide__wrapper">
                                <figure className="project-slide__blur-image">
                                    <img
                                        src={
                                            slides[previous].featuredImage
                                                .sourceUrl
                                        }
                                        srcSet={
                                            slides[previous].featuredImage
                                                .srcSet
                                        }
                                        sizes="(min-width: 990px) 50vw, 100vw"
                                        alt={slides[previous].title}
                                        ref={this.blurImage}
                                    />
                                </figure>
                                <figure className="project-slide__main-image">
                                    <img
                                        src={
                                            slides[current].featuredImage
                                                .sourceUrl
                                        }
                                        srcSet={
                                            slides[current].featuredImage.srcSet
                                        }
                                        sizes="(min-width: 990px) 50vw, 100vw"
                                        alt={slides[current].title}
                                        ref={this.mainImage}
                                    />
                                    <div
                                        ref={this.mainCurtain}
                                        className="project-slide__main-image-curtain"
                                    ></div>
                                    <TransitionLink
                                        to={`/${slides[current].slug}`}
                                        exit={{
                                            trigger: ({ exit, node }) => {
                                                window.scrollTo({
                                                    left: 0,
                                                    top: 0,
                                                })
                                                TweenMax.to(node, 0.9, {
                                                    autoAlpha: 0,
                                                })
                                            },
                                            length: 1,
                                            zIndex: 2,
                                        }}
                                        entry={{
                                            trigger: ({ entry, node }) => {
                                                TweenMax.fromTo(
                                                    node,
                                                    0.9,
                                                    {
                                                        autoAlpha: 0,
                                                    },
                                                    { autoAlpha: 1, delay: 0.9 }
                                                )
                                            },
                                            delay: 1,
                                            zIndex: 0,
                                        }}
                                    >
                                        <ViewProject />
                                    </TransitionLink>
                                </figure>
                                <div className="project-slide__content">
                                    <div className="project-slide__content-wrapper">
                                        <h4 ref={this.projectTitle}>
                                            {slides[current].title}
                                        </h4>
                                        <p
                                            ref={this.projectBlurb}
                                            dangerouslySetInnerHTML={{
                                                __html: slides[current].excerpt,
                                            }}
                                        ></p>
                                        <Button
                                            modifier={`primary`}
                                            link={`/${slides[current].slug}`}
                                            text={`learn more`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NumberedTabs
                            current={current + 1}
                            length={slides.length}
                            next={this.handleNextClick}
                            prev={this.handlePreviousClick}
                        />
                        <div className="home-projects__all">
                            <ViewAll
                                link={`/areas-of-impact`}
                                text={`view all projects`}
                            />
                        </div>
                    </div>
                    <p className="vertical-text">Areas of Impact</p>
                </ScrollableSection>
            )
        } else {
            return <></>
        }
    }
}

export default props => (
    <StaticQuery
        query={HOME_PROJECTS_QUERY}
        render={data => <HomeProjects data={data} {...props} />}
    />
)
